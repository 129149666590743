import React, { FC, Fragment } from 'react';
import { Container, Table, Button } from 'react-bootstrap';

import Layout from '../components/layout';
import SEO from '../components/seo';
import { useStaticQuery, graphql } from 'gatsby';
import { useTranslation } from 'gatsby-plugin-react-i18next';
import styled from 'styled-components';
import { PricingPlan } from '../components/pricing/model';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck } from '@fortawesome/free-solid-svg-icons';

const PlanHeading = styled.div`
  font-size: 32px;
`;

const PlanExplain = styled.div`
  font-size: 13px;
`;

const StyledTable = styled(Table)`
  &&& {
    margin-bottom: 40px;

    tr td:not(first-of-type) {
      width: 20%;
    }
    tr td {
      text-align: center;
      vertical-align: middle;
      font-size: 13px;
      border: 1px solid #dee2e6;
    }
    tr td:first-child {
      text-align: left;
    }
    tr.buttons td {
      vertical-align: top;
    }
    tr td.highlight {
      font-weight: bold;
      background: #f8f8f9;
    }
    tr td.title {
      font-weight: bold;
      background: #e3f2fd;
      font-size: 16px;
    }
    tr td.noborder {
      border: none;
    }
    tr td.notop {
      border-top: none;
    }
    tr td.nobot {
      border-bottom: none;
    }
  }
`;

const index: FC = () => {
  const data = useStaticQuery(
    graphql`
      query {
        allPricingPlan {
          nodes {
            id
            name
            monthlyPrice
            annuallyPrice
            code
            description
            formattedAnnuallyPrice
            formattedMonthlyPrice
            free
            standard
            storage
            supportType
            uptime
            preferences {
              section
              features {
                enabled
                name
              }
            }
          }
        }
      }
    `
  );
  const plans = data.allPricingPlan.nodes as PricingPlan[];
  const { preferences } = plans[0];
  const colSpan = plans.length + 1;
  const { t } = useTranslation();

  return (
    <Layout>
      <SEO title="Pricing" article />
      <Container>
        <h1 className="text-center mb-4">{t('page.pricing.callout')}</h1>
        <h4 className="text-center mb-5">{t('page.pricing.choose_plan')}</h4>
        <StyledTable>
          <tbody>
            {/* HEADING */}
            <tr>
              <td className="noborder" />
              {plans.map(plan => (
                <td key={plan.id} className="text-center nobot">
                  <PlanHeading>{plan.name}</PlanHeading>
                  {!plan.free && (
                    <>
                      <PlanHeading>{plan.formattedAnnuallyPrice}</PlanHeading>
                      <PlanExplain className="mb-3">{t('page.pricing.per_user')}</PlanExplain>
                      <PlanExplain>
                        {t('page.pricing.billed_monthly', {
                          price: plan.formattedMonthlyPrice,
                        })}
                      </PlanExplain>
                    </>
                  )}
                </td>
              ))}
            </tr>
            {/* BUTTON */}
            <tr className="buttons">
              <td className="noborder" />
              {plans.map(plan => (
                <td key={plan.id} className="text-center notop">
                  <Button block href={process.env.GATSBY_JOIN_URL}>
                    {plan.free ? t('page.pricing.create_profile') : t('page.pricing.buy_plan', { plan: plan.name })}
                  </Button>
                  <PlanExplain className="mt-2">{plan.description}</PlanExplain>
                </td>
              ))}
            </tr>
            {/* MAIN FEATURES */}
            <tr>
              <td className="title" colSpan={colSpan}>
                {t('page.pricing.key_features')}
              </td>
            </tr>
            {preferences.map((preference, pindex) => (
              <Fragment key={`section-${preference.section}`}>
                <tr>
                  <td className="highlight" colSpan={colSpan}>
                    {t(`page.pricing.section.${preference.section}`)}
                  </td>
                </tr>
                {preference.features.map((feature, findex) => (
                  <tr key={`section-${preference.section}-${feature.name}`}>
                    <td>{t(`page.pricing.feature.${preference.section}.${feature.name}`)}</td>
                    {plans.map(plan => (
                      <td key={`plan-${plan.id}-${preference.section}-${feature.name}`}>
                        {plan.preferences[pindex].features[findex].enabled && (
                          <FontAwesomeIcon icon={faCheck} width="16" />
                        )}
                      </td>
                    ))}
                  </tr>
                ))}
              </Fragment>
            ))}
            {/* OTHER FEATURES */}
            <tr>
              <td className="highlight" colSpan={colSpan}>
                {t('page.pricing.other_benefit')}
              </td>
            </tr>
            <tr>
              <td>{t('page.pricing.file_storage')}</td>
              {plans.map(plan => (
                <td key={`plan-benefit-${plan.id}`}>
                  {plan.free ? (
                    <span>
                      {t('page.pricing.total_storage', {
                        storage: plan.storage,
                      })}
                    </span>
                  ) : (
                    <span>
                      {t('page.pricing.per_collaborator', {
                        storage: plan.storage,
                      })}
                    </span>
                  )}
                </td>
              ))}
            </tr>
            <tr>
              <td>{t('page.pricing.support')}</td>
              {plans.map(plan => (
                <td key={`plan-benefit-${plan.id}`}>
                  <span>{plan.supportType}</span>
                </td>
              ))}
            </tr>
            <tr>
              <td>{t('page.pricing.uptime')}</td>
              {plans.map(plan => (
                <td key={`plan-benefit-${plan.id}`}>
                  <span>{plan.uptime}</span>
                </td>
              ))}
            </tr>
            {/* BUTTON */}
            <tr>
              <td className="noborder" />
              {plans.map(plan => (
                <td key={plan.id} className="text-center">
                  <Button block href={process.env.GATSBY_JOIN_URL}>
                    {plan.free ? t('page.pricing.create_profile') : t('page.pricing.buy_plan', { plan: plan.name })}
                  </Button>
                </td>
              ))}
            </tr>
          </tbody>
        </StyledTable>
      </Container>
    </Layout>
  );
};

export default index;
